<template>
  <div class="nav-container">
    <div class="nav">
      <div class="nav-content">
        <a-image :width="320" class="nav-logo" :preview="false" :src="logoUrl" />
        <div class="nav-list">
          <template v-for="(item, index) in list" :key="index">
            <!-- v-if="index < 3 || index == 4" -->

            <div class="nav-btn" :class="navIdx == index ? 'nav-btn_active' : 'nav-btn'" @click="changeIdx(index)"
              type="primary">
              {{ item.name }}
            </div>
            <!-- <a
              :class="navIdx == index ? 'nav-btn_active' : 'nav-btn'"
              class="nav-btn"
              v-else
              :href="item.path"
              target="_blank"
              >{{ item.name }}</a
            > -->
          </template>
        </div>
        <div class="nav-right" v-if="!ifLogin">
          <a-button class="login-btn" :class="loginIdx == 0 ? 'nav-btn_active' : 'login-btn'" type="text"
            @click="toLogin">登录</a-button>
          <div class="nav-line">|</div>
          <a-button class="login-btn" :class="loginIdx == 1 ? 'nav-btn_active' : 'login-btn'" type="text"
            @click="toRegister">注册</a-button>
        </div>
        <div class="nav-right" v-else>
          <div class="user-head">
            <a-image :width="20" :preview="false" :src="userHead" @click="goUserCenter" />
          </div>
          <div class="user-name" @click="goUserCenter">
            {{ hideNumber(userMsg.contact_phone) }}
          </div>
          <a-button class="login-btn nav-btn_active" type="text" @click="showQuitBox = true">退出</a-button>
        </div>
      </div>
    </div>
    <div class="system-height"></div>
    <a-modal v-model:visible="showQuitBox" centered :closable="false" :footer="null">
      <div class="loginout-title">提示</div>
      <div class="loginout-center">
        <div class="loginout-item">
          <div class="loginout-name">确定要退出登录吗？</div>
        </div>
      </div>
      <div class="loginout-buttom">
        <a-button class="loginout-cancel_btn" size="large" type="primary" @click="showQuitBox = false">取消</a-button>
        <a-button class="loginout-confirm_btn" size="large" type="primary" @click="handleOk">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Button, Image, Modal, message } from "ant-design-vue";
export default {
  name: "Login",
  components: {
    AButton: Button,
    AImage: Image,
    AModal: Modal,
  },
  data: () => {
    return {
      list: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "大赛通知",
          path: "/introduction",
        },
        {
          name: "大赛报名",
          path: "/enroll",
        },
        {
          name: "作品确权",
          path: "/confirm",
        },
        {
          name: "作品申报",
          path: "/declare",
        },
      ],
      navIdx: 0,
      loginIdx: null,
      title: "",
      logoUrl: require("../assets/img/logo.png"),
      userHead: require("../assets/img/user@2x.png"),
      ifLogin: false,
      userMsg: {},
      showQuitBox: false,
    };
  },
  created() {
    if (localStorage.getItem("TOKEN")) {
      this.ifLogin = true;
    }
    if (localStorage.getItem("userInfo")) {
      this.userMsg = JSON.parse(localStorage.getItem("userInfo"));
    }
    if (this.$route.path.includes("register")) {
      this.loginIdx = 1;
    } else {
      this.loginIdx = 0;
    }
    if (this.$route.path.includes("introduction")) {
      this.navIdx = 1;
    }
    if (this.$route.path.includes("enroll")) {
      this.navIdx = 2;
    }
    if (this.$route.path.includes("mine")) {
      this.navIdx = null;
    }
    if (this.$route.path.includes("declare")) {
      this.navIdx = 4;
    }
  },
  methods: {
    hideNumber(val) {
      const num = String(val);
      if (!num) {
        return num;
      } else {
        var lastnum = num.substring(0, 3);
        var nextnum = num.substring(num.length - 4);
        return lastnum + "****" + nextnum;
      }
    },
    toLogin() {
      this.loginIdx = 0;
      this.$router.push({ path: "login" });
      this.$emit("toLogin", this.loginIdx);
    },
    toRegister() {
      this.loginIdx = 1;
      this.$router.push({ path: "register" });
      this.$emit("toRegister", this.loginIdx);
    },
    changeIdx(index) {
      this.navIdx = index;
      this.$emit("changeIdx", index);
      setTimeout(() => {
        this.$router.push({ path: this.list[index].path });
      }, 100);
    },
    handleOk() {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("userInfo");
      message.success("退出登录成功");
      setTimeout(() => {
        this.$router.push({ path: "login" });
      }, 200);
    },
    goUserCenter() {
      this.$router.push({ path: "Mine" });
    },
  },
};
</script>
<style scoped>
.nav-container {
  position: relative;
}

.system-height {
  height: 64px;
}

.nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999999;
  background: #ffffff;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 120px;
}

.nav-logo {
  width: 320px;
  height: 28px;
}

.nav-list {
  display: flex;
  align-items: center;
}

.nav-btn {
  min-width: 88px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background: #ffffff;
  border: none;
  color: #121212;
  cursor: pointer;
}

.nav-btn:first-child {
  min-width: 58px;
}

.nav-btn_active {
  font-weight: 500;
  color: #0258dc;
}

.nav-right {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .user-head {
  cursor: pointer;
} */
.login-btn {
  font-size: 14px;
  font-weight: 500;
}

.user-name {
  margin: 0 10px;
  color: #0258dc;
}

.loginout-title {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #000000;
  /* border-bottom: 2px solid #f4f4f4; */
}

.loginout-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
}

.loginout-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.loginout-name {
  margin-right: 6px;
  font-size: 16px;
  font-family: Alibaba, Alibaba-Regular;
  font-weight: 500;
  color: #1446a0;
  margin-right: 10px;
}

.loginout-buttom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.loginout-cancel_btn {
  width: 177px;
  height: 50px;
  background: linear-gradient(270deg, #ccd2d5, #dee0e1 98%);
  border-radius: 5px;
  box-shadow: 0px 5px 24px 0px rgba(155, 154, 154, 0.24);
  margin-right: 40px;
  border: none;
}

.loginout-confirm_btn {
  width: 177px;
  height: 50px;
  background: linear-gradient(270deg, #295bfe, #517aff);
  border-radius: 5px;
  box-shadow: 0px 5px 24px 0px rgba(80, 116, 235, 0.55);
}
</style>
