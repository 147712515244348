<template>
  <div class="foot">
    <div class="foot-msg">
      <!-- Copyright  -->
      © 2023 数广网安
    </div>
    <div class="foot-msg">
      <!-- 京ICP备XXXXXX37 / 京公网安备 XXXXXXXXXX5 / 技术支持电话：010-XXXXXXXX -->
      技术支持电话：18807716641
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data: () => {
    return {};
  },
  methods: {},
};
</script>
<style>
.foot {
  padding: 30px 0px;
  background: #313131;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 980;
  /* z-index: 9999; */
}
.foot-msg {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
</style>
