import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import axios from "./utils/request";

import { message } from "ant-design-vue";
router.beforeEach((to, from, next) => {
  let state = localStorage.getItem("TOKEN");
  if (to.meta.requireAuth) {
    if (!state) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
  console.log(state);
});
message.config({
  duration: 2, // 持续时间
  top: `200px`, // 到页面顶部距离
  maxCount: 3, // 最大显示数, 超过限制时，最早的消息会被自动关闭
});
const app = createApp(App);
app.use(antd);

createApp(App).use(store).use(router).mount("#app");
app.config.globalProperties.$axios = axios;
