<template>
  <div class="banner">
    <div class="banner-default" v-if="size == 'default'">
      <a-image :preview="false" :src="bannerBg_default" />
      <div class="banner-title">{{ title }}</div>
    </div>
    <div class="banner-small" v-if="size == 'small'">
      <a-image :preview="false" :src="bannerBg_small" />
      <div class="banner-title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
import { Image } from "ant-design-vue";

export default {
  name: "Banner",
  components: {
    AImage: Image,
  },
  props: {
    size: { type: String, default: "default" }, // small
    fontSize: { type: String, default: "80" },
  },
  data: () => {
    return {
      bannerBg_default: require("../assets/img/banner_bg.jpg"),
      bannerBg_small: require("../assets/img/banner2@2x.jpg"),
      title: "广西壮族自治区第八届“八桂杯”BIM技术应用大赛",
    };
  },
};
</script>

<style scoped>
/* @font-face {
    font-family: 'YouSheBiaoTiHei';
    src: url(../assets/fonts/YouSheBiaoTiHei.ttf);
} */

.banner-default {
  position: relative;
  /* min-width: 1874px; */
  /* height: 50vh; */
}
.banner-small {
  position: relative;
}

.banner-title {
  max-width: 80%;
  min-width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 3px 3px 0 #6dcec3;
  user-select: none;
  font-weight: bold;
  font-size: 3.5vw !important;
}
</style>
