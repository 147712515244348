<template>
  <div class="home">
    <Navbar
      @changeIdx="changeIdx"
      @toLogin="toLogin"
      @toRegister="toRegister"
    ></Navbar>
    <div class="container">
      <a-carousel autoplay>
        <div class="banner-box">
          <banner />
        </div>
      </a-carousel>
      <div class="tab-list">
        <div
          class="tab-image"
          v-for="(item, index) in tabList"
          :key="index"
          @click="this.$router.push({ path: item.path })"
        >
          <a-image
            :width="236"
            :height="155"
            :preview="false"
            :src="item.url"
          />
          <div class="content">
            <div class="content-icon">
              <a-avatar :src="item.icon" :size="55" shape="square" />
            </div>
            <div class="content-label">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="sponsor-msg">
        <!-- <div class="sponsor-bg"> -->
        <img class="sponsor-bg" :src="sponsorBg" />
        <!-- </div> -->
        <div class="sponsor-box">
          <a-image
            :width="138"
            :height="22"
            :preview="false"
            :src="sponsorImg"
          />
          <div class="sponsor-address">
            <span>指导单位：</span>
            <div class="sponsor-text">广西壮族自治区住房和城乡建设厅</div>
          </div>
          <div class="sponsor-list">
            <div
              class="sponsor-item"
              v-for="(item, index) in sponsorList"
              :key="index"
            >
              <div class="sponsor-address">
                <span>{{ item.name }}：</span>
              </div>
              <div
                v-for="(i, idx) in item.list"
                :key="idx"
                class="sponsor-text"
              >
                {{ i }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import { Carousel, Image, Avatar } from "ant-design-vue";
import Navbar from "../components/Navbar.vue";
import Foot from "../components/Foot.vue";
import Banner from "../components/Banner.vue";
export default {
  name: "Home",
  components: {
    Navbar,
    ACarousel: Carousel,
    AImage: Image,
    AAvatar: Avatar,
    Foot,
    Banner,
  },
  data: () => {
    return {
      sponsorImg: require("../assets/img/bt@2x.png"),
      sponsorBg: require("../assets/img/sssk_bg@2x.png"),
      tabList: [
        {
          url: require("../assets/img/1.png"),
          icon: require("../assets/img/1_icon.png"),
          label: "大赛通知",
          path: "introduction",
        },
        {
          url: require("../assets/img/2.png"),
          icon: require("../assets/img/2_icon.png"),
          label: "大赛报名",
          path: "enroll",
        },
        {
          url: require("../assets/img/3.png"),
          icon: require("../assets/img/3_icon.png"),
          label: "作品确权",
          path: "confirm",
        },
        {
          url: require("../assets/img/4.png"),
          icon: require("../assets/img/4_icon.png"),
          label: "作品申报",
          path: "declare",
        },
      ],
      sponsorList: [
        {
          name: "主办单位",
          list: [
            "广西工程建设标准化协会",
            "广西建筑业联合会",
            "广西勘察设计协会",
            "广西建设工程造价管理协会",
            "广西建设教育协会",
          ],
        },
        {
          name: "承办单位",
          list: [
            "广西工程建设标准化协会",
            "广西建筑信息模型（BIM）技术发展联盟",
          ],
        },
        {
          name: "协办单位",
          list: [
            "广西路桥工程集团有限公司",
            // "太平洋建设集团有限公司",
            // "广西两湾建设有限公司",
            // "广西建设职业技术学院"
          ],
        },
        {
          name: "支持单位",
          list: [
            "深圳市斯维尔科技股份有限公司",
            "广西数广网安科技有限公司",
            "广联达科技股份有限公司南宁分公司",
            // "上格（北京）科技有限公司",
          ],
        },
      ],
    };
  },
  methods: {
    changeIdx(index) {
      console.log(index);
    },
    toLogin() {
      this.loginIdx = 0;
    },
    toRegister() {
      this.loginIdx = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .tab-list {
    margin-top: 20px;
  }
}

.tab-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 80vw;
  min-width: 600px;
}

.tab-image {
  cursor: pointer;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-label {
      margin-top: 10px;
      color: white;
      font-size: 1.1rem;
    }
  }
}

.sponsor-msg {
  padding-bottom: 60px;
  position: relative;
}

.sponsor-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.sponsor-box {
  position: relative;
  z-index: 999;
  width: 80vw;
  margin: 0 auto;
}

.sponsor-address {
  font-size: 16px;
  font-weight: 600;
  color: #171717;
  display: flex;
  align-items: center;
  margin: 20px 0;
  border-bottom: 1px solid #005ae4;
}

.sponsor-text {
  font-size: 14px;
  color: #171717;
}

.sponsor-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sponsor-item {
  min-width: 249px;
}
</style>
