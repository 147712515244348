//axiosInstance.js
//导入axios
import axios from "axios";
import {
  message
} from "ant-design-vue";
import router from "../router";

//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
// 这里不需要配置BaseUrl，否则无法代理
const request = axios.create({});
request.interceptors.request.use(
  (config) => {
    return config;
  },
  function (error) {
    Promise.reject(error);
  }
);
request.interceptors.response.use(
  (res) => {
    const data = res.data;
    const {
      errcode: errcode,
      msg: msg
    } = data;
    const {
      status: status
    } = res;
    console.log(res, "res");
    if (status === 500) {
      message.error("请求失败");
      return;
    }
    if (errcode === 400 && msg == "请先授权登录") {
      message.warning("登录过期");
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("userInfo");
      setTimeout(() => {
        router.push({
          path: "/login"
        });
      }, 1500);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 200);
      return;
    }
    return data;
  },
  (error) => {
    console.log(error.code);
    if (error.code == "ERR_BAD_RESPONSE") {
      message.error("接口请求失败", [3]);
    } else if (error.code == "ERR_NETWORK") {
      message.error("网络错误", [3]);
    } else {
      message.error(error.message, [3]);
    }
    Promise.reject(error);
  }
);

//导出我们建立的axios实例模块，ES6 export用法
export default request;

export const GET = (url = '', parem = {}, options = {}) => {
  if (url == '') return
  const TOKEN = localStorage.getItem("TOKEN")
  return request({
    method: "GET",
    data: parem,
    url: `${url}?token=${TOKEN}`,
    ...options
  })
}

export const POST = (url = '', parem = {}, options = {}) => {
  if (url == '') return
  const TOKEN = localStorage.getItem("TOKEN")
  return request({
    method: "POST",
    data: parem,
    url: `${url}?token=${TOKEN}`,
    ...options
  })
}

export const PUT = (url = '', parem = {}, options = {}) => {
  if (url == '') return
  return request({
    method: "PUT",
    data: parem,
    url: `${url}`,
    ...options
  })
}

export const DELETE = (url = '', parem = {}, options = {}) => {
  if (url == '') return
  return request({
    method: "DELETE",
    data: parem,
    url: `${url}`,
    ...options
  });
}