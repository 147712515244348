import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: () => import("../views/MatchBrief.vue"),
  },
  {
    path: "/enroll",
    name: "Enroll",
    component: () => import("../views/Enroll.vue"),
  },
  {
    path: "/declare",
    name: "Declare",
    component: () => import("../views/Declare.vue"),
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: () => import("../views/Confirm.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    component: () => import("../views/Mine.vue"),
    meta: {
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
